<template>
  <div class="logo">
    <div class="login">
      <div class="left">
        <img src="../../assets/login/logo.gif" alt="" />
        <div class="ruzhu">企业入驻</div>
      </div>
      <div class="right">
        <div class="left">
          <div class="shangpin">{{ shang }}</div>
          <img
            @click.stop="ShowHidden = !ShowHidden"
            class="img1"
            src="../../assets/home/xiajiantou.png"
          />
          <div class="xuanze" @click.stop="" v-if="ShowHidden">
            <div
              class="yincang"
              v-for="(item, index) in xianshi"
              :key="index"
              @click="ConBtn(index)"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="center">
          <input
            v-model="searchInput"
            type="text"
            placeholder="输入产品编号  产品名称  关键词"
            onkeyup="this.value=this.value.replace(/[, ]/g,'')"
            @keyup.enter.prevent="handleSearchClick"
          />
        </div>
        <div class="right" @click="handleSearchClick">搜索</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Search',
  props: ['msg'],
  data() {
    return {
      aaa: '',
      ShowHidden: false,
      xianshi: ['商品', this.$t('common.shop')],
      shang: '商品',
      searchInput: '',
    };
  },
  mounted() {
    document.addEventListener('click', this.HiddenClick);
  },
  methods: {
    HiddenClick() {
      this.ShowHidden = false;
    },
    ConBtn(val) {
      this.ShowHidden = false;
      this.shang = this.xianshi[val];
    },
    handleSearchClick() {
      if (this.shang == '商品') {
        this.$router.push({
          path: '/search',
          query: {
            q: this.searchInput,
          },
        });
      } else {
        this.$router.push({
          path: '/search_store',
          query: {
            q: this.searchInput,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 100%;
  min-width: 1200px;
  background: #fff;
  .login {
    width: 1200px;
    height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }
  .left {
    margin-left: 33px;
    display: flex;
    align-items: center;
    img {
      width: 158px;
      height: 44px;
    }
    .ruzhu {
      margin-left: 10.5px;
      color: #2659f2;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .right {
    display: flex;
    height: 42px;
    line-height: 42px;
    border: 2px solid #6b8fff;
    border-right: none;
    .left {
      width: 95px;
      display: flex;
      align-items: center;
      color: #666;
      border-right: 2px solid #6b8fff;
      position: relative;
      cursor: pointer;
      font-size: 16px;

      img {
        width: 15px;
        height: 10px;
        margin-left: 20px;
      }
      .xuanze {
        width: 70px;
        background: #fff;
        position: absolute;
        left: -18px;
        top: 45px;
        z-index: 999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 5px;
      }
    }
    .center {
      width: 480px;
      input {
        border: 0;
        outline: none;
        width: 100%;
        padding-left: 10px;
      }
    }
    .right {
      color: #fffeff;
      background: #1953ff;
      width: 133px;
      display: flex;
      justify-content: center;
      border: none;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>
